.header {
    position: relative;
    top: 0px;
    width: 100vw;
}

/* .locations, .contact {
    padding: 1rem;
}

.field {
    margin: 1rem;
    font-size: 1rem;
}

.field svg {
    margin-right: 0.3rem;
    color: grey;
}

.socials {
    padding-top: 0.5rem;
}

.socials a {
    margin: 2rem;
    opacity: 0.8;
} */
