.splash {
    width: 100vw;
}

.splash > div {
    width: 100%;
    display: flex;
    /* height: calc(100vh - 2.1rem); */
    height: 100vh;
    /* margin-bottom: 2.1rem; */
    flex-direction: column;
    justify-content: center;
    /* border: 2px solid gold; */
    align-items: center;
}


.splash > .splashLeft:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: inherit;
    height: inherit;
    z-index: 1;
    opacity: 0.2;
    background-image: url('https://images.unsplash.com/photo-1588708908465-2a1a34ce25d3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1867&q=80');
    background-repeat: no-repeat;
    background-position: 50% 0;
    -ms-background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-size: cover;
}

.splashRight {
    /* background-color: rgb(197,209,202); */
    background-color: rgba(166,180,187,0.6);
    /* background-color: rgba(178,170,141,0.6); */
    /* background-color: rgba(222,218,183,0.6); */
}



.splash .container {
    /* height: 50%; */
    padding: 5%;
    width: 80%;
    display: inherit;
    flex-direction: inherit;
    justify-content: space-evenly;
    border: 3px solid rgba(178,170,141,0.2);
    border-radius: 2px;
}


.splashLeft .container {
    background-color: rgb(222,218,183,0.6);
    z-index: 2;
}

.splashRight .container {
         /* background-color: rgba(0,0,0, 0.3); */
         /* background-color: rgba(166,180,187,0.6);
         background-color: rgba(178,170,141,0.6); */
         background-color: rgba(222,218,183,0.6);
}

.splashRight .tagline {
    font-size: 1rem;
}

.teaser {
    font-size: 2rem;
}

.taglineLead {
    font-weight: 1000;
    color: grey;
}

.tagline, .logoTag, .teaser, .taglineLead {
    text-transform: uppercase;
}

.tagline p:before, .tagline p:after {
    content: ' ○ '
}

.tagline p {
    margin: 0.5rem;
    font-size: 2rem;
}

.logoTag {
    font-size: 3rem;
    margin: 1.5rem;
}

.form {
    margin: 1rem;
}

.form input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 1rem;
    border: none;
    font-size: 1.2rem;
}

.form input[name="to_email"]{
    border-radius: 3px 0 0 3px;
}

.form input[type="submit"]{
    background-color: rgba(166,180,187,0.6);
    border-radius: 0 3px 3px 0;
    color: white;
}

.err {
    color: darkred;
    font-size: 0.8rem;
    font-weight: 700;
}

.succ {
    color: rgb(79,91,88);
    font-weight: 800;
    padding: 1rem;
}

.locations, .contact {
    padding: 1rem;
}

.fields {
    display: flex;
    flex-wrap: wrap;
    width: 60%;
    justify-content: space-evenly;
    align-content: center;
    align-self: center;
    margin: 1rem;
}

.field {
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
}

.field svg {
    margin-right: 0.3rem;
    color: grey;
}

.socials {
    padding-top: 0.5rem;
}

.socials a {
    margin: 1rem;
    opacity: 0.8;
}

@media(max-width: 590px){

    .teaser {
        margin: 1rem;
    }

    .fields {
        flex-direction: column;
        width: 100%;
    }

    .form input {
        font-size: 0.8rem;
    }

    .tagline p {
        margin: 0.5rem;
        font-size: 0.8rem;
    }
    
}

@media(max-width: 1130px){


    .teaser {
        margin: 1rem;
        font-size: 1.4rem;
    }

    .logoTag {
        font-size: 2rem;
        margin: 1.5rem;
    }

    .fields {
        width: 100%;
    }

    .tagline p {
        font-size: 0.8rem;
    }
    
    .form input {
        font-size: 0.8rem;
    }
}

@media(max-width: 380px){
    .form input {
        font-size: 0.7rem;
    }
}

@media(max-height: 510px){
    .splash .container {
        /* height: 50%; */
        padding: 3%;
        width: 90%;
    }
}