.footer {
    /* position: absolute; */
    bottom: 0;
    width: 100vw;
    background-color: rgba(237, 134, 9, 0.2);
    height: 2.1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1rem;
}