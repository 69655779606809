@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100&family=Milonga&family=UnifrakturMaguntia&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
}
.header_header__30zsP {
    position: relative;
    top: 0px;
    width: 100vw;
}

/* .locations, .contact {
    padding: 1rem;
}

.field {
    margin: 1rem;
    font-size: 1rem;
}

.field svg {
    margin-right: 0.3rem;
    color: grey;
}

.socials {
    padding-top: 0.5rem;
}

.socials a {
    margin: 2rem;
    opacity: 0.8;
} */

.footer_footer__5IoHz {
    /* position: absolute; */
    bottom: 0;
    width: 100vw;
    background-color: rgba(237, 134, 9, 0.2);
    height: 2.1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1rem;
}
.splash_splash__YZPz1 {
    width: 100vw;
}

.splash_splash__YZPz1 > div {
    width: 100%;
    display: flex;
    /* height: calc(100vh - 2.1rem); */
    height: 100vh;
    /* margin-bottom: 2.1rem; */
    flex-direction: column;
    justify-content: center;
    /* border: 2px solid gold; */
    align-items: center;
}


.splash_splash__YZPz1 > .splash_splashLeft__1GRlK:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: inherit;
    height: inherit;
    z-index: 1;
    opacity: 0.2;
    background-image: url('https://images.unsplash.com/photo-1588708908465-2a1a34ce25d3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1867&q=80');
    background-repeat: no-repeat;
    background-position: 50% 0;
    -ms-background-size: cover;
    background-size: cover;
}

.splash_splashRight__2dZ1j {
    /* background-color: rgb(197,209,202); */
    background-color: rgba(166,180,187,0.6);
    /* background-color: rgba(178,170,141,0.6); */
    /* background-color: rgba(222,218,183,0.6); */
}



.splash_splash__YZPz1 .splash_container__1p-EN {
    /* height: 50%; */
    padding: 5%;
    width: 80%;
    display: inherit;
    flex-direction: inherit;
    justify-content: space-evenly;
    border: 3px solid rgba(178,170,141,0.2);
    border-radius: 2px;
}


.splash_splashLeft__1GRlK .splash_container__1p-EN {
    background-color: rgb(222,218,183,0.6);
    z-index: 2;
}

.splash_splashRight__2dZ1j .splash_container__1p-EN {
         /* background-color: rgba(0,0,0, 0.3); */
         /* background-color: rgba(166,180,187,0.6);
         background-color: rgba(178,170,141,0.6); */
         background-color: rgba(222,218,183,0.6);
}

.splash_splashRight__2dZ1j .splash_tagline__2NqgT {
    font-size: 1rem;
}

.splash_teaser__Znlee {
    font-size: 2rem;
}

.splash_taglineLead__g4qfy {
    font-weight: 1000;
    color: grey;
}

.splash_tagline__2NqgT, .splash_logoTag__1C7sM, .splash_teaser__Znlee, .splash_taglineLead__g4qfy {
    text-transform: uppercase;
}

.splash_tagline__2NqgT p:before, .splash_tagline__2NqgT p:after {
    content: ' ○ '
}

.splash_tagline__2NqgT p {
    margin: 0.5rem;
    font-size: 2rem;
}

.splash_logoTag__1C7sM {
    font-size: 3rem;
    margin: 1.5rem;
}

.splash_form__w0yFz {
    margin: 1rem;
}

.splash_form__w0yFz input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 1rem;
    border: none;
    font-size: 1.2rem;
}

.splash_form__w0yFz input[name="to_email"]{
    border-radius: 3px 0 0 3px;
}

.splash_form__w0yFz input[type="submit"]{
    background-color: rgba(166,180,187,0.6);
    border-radius: 0 3px 3px 0;
    color: white;
}

.splash_err__3pas7 {
    color: darkred;
    font-size: 0.8rem;
    font-weight: 700;
}

.splash_succ__2Bpqw {
    color: rgb(79,91,88);
    font-weight: 800;
    padding: 1rem;
}

.splash_locations__2-_MY, .splash_contact__34Fp3 {
    padding: 1rem;
}

.splash_fields__2ToG_ {
    display: flex;
    flex-wrap: wrap;
    width: 60%;
    justify-content: space-evenly;
    align-content: center;
    align-self: center;
    margin: 1rem;
}

.splash_field__8AU6H {
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
}

.splash_field__8AU6H svg {
    margin-right: 0.3rem;
    color: grey;
}

.splash_socials__3tOdl {
    padding-top: 0.5rem;
}

.splash_socials__3tOdl a {
    margin: 1rem;
    opacity: 0.8;
}

@media(max-width: 590px){

    .splash_teaser__Znlee {
        margin: 1rem;
    }

    .splash_fields__2ToG_ {
        flex-direction: column;
        width: 100%;
    }

    .splash_form__w0yFz input {
        font-size: 0.8rem;
    }

    .splash_tagline__2NqgT p {
        margin: 0.5rem;
        font-size: 0.8rem;
    }
    
}

@media(max-width: 1130px){


    .splash_teaser__Znlee {
        margin: 1rem;
        font-size: 1.4rem;
    }

    .splash_logoTag__1C7sM {
        font-size: 2rem;
        margin: 1.5rem;
    }

    .splash_fields__2ToG_ {
        width: 100%;
    }

    .splash_tagline__2NqgT p {
        font-size: 0.8rem;
    }
    
    .splash_form__w0yFz input {
        font-size: 0.8rem;
    }
}

@media(max-width: 380px){
    .splash_form__w0yFz input {
        font-size: 0.7rem;
    }
}

@media(max-height: 510px){
    .splash_splash__YZPz1 .splash_container__1p-EN {
        /* height: 50%; */
        padding: 3%;
        width: 90%;
    }
}
/* font-family: 'Josefin Sans', sans-serif;
font-family: 'Milonga', cursive;
font-family: 'UnifrakturMaguntia', cursive; */

.App {
  text-align: center;
  font-family: 'Josefin Sans', sans-serif;
}

h1 {
  font-size: 5rem;
  font-family: 'Milonga', cursive;
  letter-spacing: -0.3rem;
}

cap {
  letter-spacing: -1.3rem;
  font-size: 8rem;
  position: relative;
  top: 1.7rem;
  /* font-family: 'UnifrakturMaguntia', cursive; */
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  font-weight: bolder;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media(max-width: 1130px){
  h1 {
    font-size: 3.8rem;
    letter-spacing: -0.35rem;
  }

  cap {
    letter-spacing: -1.2rem;
    font-size: 6rem;
    position: relative;
    top: 1.1rem;
  }
}


