@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100&family=Milonga&family=UnifrakturMaguntia&display=swap');
/* font-family: 'Josefin Sans', sans-serif;
font-family: 'Milonga', cursive;
font-family: 'UnifrakturMaguntia', cursive; */

.App {
  text-align: center;
  font-family: 'Josefin Sans', sans-serif;
}

h1 {
  font-size: 5rem;
  font-family: 'Milonga', cursive;
  letter-spacing: -0.3rem;
}

cap {
  letter-spacing: -1.3rem;
  font-size: 8rem;
  position: relative;
  top: 1.7rem;
  /* font-family: 'UnifrakturMaguntia', cursive; */
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  font-weight: bolder;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media(max-width: 1130px){
  h1 {
    font-size: 3.8rem;
    letter-spacing: -0.35rem;
  }

  cap {
    letter-spacing: -1.2rem;
    font-size: 6rem;
    position: relative;
    top: 1.1rem;
  }
}

